import { FC, useCallback, useEffect, useState, useRef } from 'react';
import TimePicker from '@mui/lab/TimePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import moment from 'moment-timezone';

import { useMutation, useQuery } from 'react-query';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from 'store/configureStore';
import { setFilter } from 'store/ducks/tournaments/tournaments';
import { getSchedulerRooms, getTournaments, maxBuyin } from 'api/tournaments';

import timezones from 'constants/timezones';
import Select from 'components/Select/Select';

import MaxNumberFilter from './components/MaxNumberFilter/MaxNumberFilter';
import RoomLogo from './components/RoomLogo/RoomLogo';
import AccuracyLogo from './components/AccuracyLogo/AccuracyLogo';
import WaterMark from './components/WaterMark/WaterMark';
import { Button, FormControl, FormControlLabel, FormGroup, Switch } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const weekDays = [
    { value: 'Monday', label: 'Понедельник' },
    { value: 'Tuesday', label: 'Вторник' },
    { value: 'Wednesday', label: 'Среда' },
    { value: 'Thursday', label: 'Четверг' },
    { value: 'Friday', label: 'Пятница' },
    { value: 'Saturday', label: 'Суббота' },
    { value: 'Sunday', label: 'Воскресенье' },
];

const Tournaments: FC = () => {
    const filters = useSelector((state: AppState) => state.tournaments);

    const dispatch = useDispatch();

    const [showSelectedOnly, setShowSelectedOnly] = useState(false);
    const [tournaments, setTournaments] = useState<any>([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [startTime, setStartTime] = useState(moment.tz(filters.tz.value).subtract(30, 'minutes'));
    const [startDay, setStartDay] = useState(weekDays[moment.tz(filters.tz.value).weekday()]);
    const [endTime, setEndTime] = useState(moment.tz(filters.tz.value).add(12, 'hours'));
    const [endDay, setEndDay] = useState(weekDays[moment.tz(filters.tz.value).add(12, 'hours').weekday()]);
    const selectedRowIds = useRef(new Set());
    const [gridApi, setGridApi] = useState(null);
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedAbi, setSelectedAbi] = useState(0);
    const [selectedTotaolBi, setSelectedTotalBi] = useState(0);

    const roomsQuery = useQuery('scheduler-rooms', getSchedulerRooms);
    const maxBuyinQuery = useQuery("max-buyin", maxBuyin)
    const mutation = useMutation(getTournaments, {
        onSuccess: (data) => {
            setTournaments(data);
        },
    });

    useEffect(() => {
        if (roomsQuery && roomsQuery.data && !filters.rooms.length) dispatch(setFilter({ rooms: roomsQuery.data.networks }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomsQuery.data]);

    useEffect(() => {
        handleSearchClick()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onGridReady = params => {
        setGridApi(params.api);
        params.api.setRowData(tournaments?.items);
    };

    const onSelectionChanged = () => {
        const selectedNodes = gridApi.getSelectedNodes();
        selectedRowIds.current.clear();
        let newSelectedRows = [];
        selectedNodes.forEach(node => {
            selectedRowIds.current.add(node.data.hash + node.data.day_number);
            newSelectedRows.push(node.data);
        });
        setSelectedRows(newSelectedRows);
        setSelectedCount(selectedNodes.length);
        setSelectedAbi(selectedNodes.reduce((acc, node) => acc + node.data.usd_buyin, 0) / selectedNodes.length);
        setSelectedTotalBi(selectedNodes.reduce((acc, node) => acc + node.data.usd_buyin, 0));
    };

    const resetSelection = () => {
        gridApi.deselectAll();
        selectedRowIds.current.clear();
        setSelectedRows([]);
        setSelectedCount(0);
        setSelectedAbi(0);
        setSelectedTotalBi(0);
    }

    const toggleShowSelectedOnly = () => {
        const newShowSelectedOnly = !showSelectedOnly;
        setShowSelectedOnly(newShowSelectedOnly);

        if (gridApi) {
            // Define the row data based on the toggle state
            const rowData = newShowSelectedOnly ? selectedRows : tournaments?.items;

            // Update the row data
            gridApi.setRowData(rowData);

            // Wait for grid to process the row data change
            setTimeout(() => {
                gridApi.forEachNode(node => {
                    if (selectedRowIds.current.has(node.data.hash + node.data.day_number)) {
                        node.setSelected(true);
                    }
                });

            }, 0);
        }
    };

    const isRowSelectable = (node) => {
        return node.data.score !== -2;
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.setRowData(tournaments?.items);
            setTimeout(() => {
                gridApi.forEachNode(node => {
                    if (selectedRowIds.current.has(node.data.hash + node.data.day_number)) {
                        node.setSelected(true);
                    }
                });
            }, 0);
        }
    }, [tournaments, gridApi]);

    /**
     * Compare tournaments based on day number and time.
     * First tournaments are sorted by day and inside day they are sorted by time
     * */
    const customTimeSort = (a: string, b: string, { data: aData }, { data: bData }) => {
        if (aData.dayNum > bData.dayNum) return 1;
        if (aData.dayNum < bData.dayNum) return -1;
        if (aData.timestamp > bData.timestamp) return 1;
        if (aData.timestamp < bData.timestamp) return -1;

        return 0;
    };

    const toggleRoom = (room: string) => {
        const rooms = filters.rooms.slice();

        if (rooms.includes(room)) rooms.splice(rooms.indexOf(room), 1);
        else rooms.push(room);

        dispatch(setFilter({ rooms }));
    };

    const changeTimezone = (value: string) => {
        const m = moment.tz(value).subtract(30, 'minutes');

        setStartTime(m.clone());
        setStartDay(weekDays[m.weekday()]);
        setEndTime(m.clone().add(12, 'hours'));
        setEndDay(weekDays[m.clone().add(12, 'hours').weekday()]);

        dispatch(setFilter({ tz: { value, label: value } }));
    };

    const handleCopy = useCallback((text) => {
        navigator.clipboard.writeText(text);
    }, []);

    const nameRenderer = useCallback(({ data }) => {
        const handleCopyClick = () => {
            handleCopy(data.hash);
        };

        return (
            <span onClick={handleCopyClick} className="cursor-pointer">{data.name.toString()}</span>
        );
    }, [handleCopy]);

    const handleSearchClick = () => {
        if (moment(startTime, 'HH:mm', true).isValid() && moment(endTime, 'HH:mm', true).isValid()) {
            mutation.mutate({ filters, startTime, startDay, endTime, endDay });
        }
    };

    return (
        <div className="flex-1 flex flex-col">
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-2 lg:mb-8 mb-4">
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Рум</p>
                    <div className="flex flex-wrap">
                        {!roomsQuery.isLoading && roomsQuery && roomsQuery.data?.networks.map((val, index) => (
                            <div className="mr-2 mb-2" key={index}>
                                <Chip
                                    label={val}
                                    className={filters.rooms.find((item: any) => item === val) ? 'bg-blue-400' : 'bg-gray-300'}
                                    color={filters.rooms.find((item: any) => item === val) ? 'primary' : 'default'}
                                    onClick={() => toggleRoom(val)}
                                    clickable
                                    disabled={!!showSelectedOnly}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Тип</p>
                    <div className="flex w-2/5 space-x-2">
                        <Chip
                            label="Regular"
                            className={filters.typeFilterRegular ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterRegular ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterRegular: !filters.typeFilterRegular }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                        <Chip
                            label="Turbo"
                            className={filters.typeFilterTurbo ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterTurbo ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterTurbo: !filters.typeFilterTurbo }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                    </div>
                </div>
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Баунти</p>
                    <div className="flex w-2/5 space-x-2">
                        <Chip
                            label="Freezeout"
                            className={filters.typeFilterFreezeout ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterFreezeout ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterFreezeout: !filters.typeFilterFreezeout }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                        <Chip
                            label="Knockout/Bounty"
                            className={filters.typeFilterBounty ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterBounty ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterBounty: !filters.typeFilterBounty }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                        <Chip
                            label="Rebuy"
                            className={filters.typeFilterRebuy ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.typeFilterRebuy ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ typeFilterRebuy: !filters.typeFilterRebuy }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                    </div>
                </div>
                <div>
                    <p className="font-medium mb-2 text-gray-700 text-sm">Рейтинг</p>
                    <div className="flex w-2/5 space-x-2">
                        <Chip
                            label="Лучшие"
                            className={filters.filterTop ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterTop ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterTop: !filters.filterTop }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                        <Chip
                            label="Хорошие"
                            className={filters.filterGood ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterGood ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterGood: !filters.filterGood }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                        <Chip
                            label="Средние"
                            className={filters.filterAverage ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterAverage ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterAverage: !filters.filterAverage }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                        <Chip
                            label="Плохие"
                            className={filters.filterBad ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterBad ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterBad: !filters.filterBad }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                        <Chip
                            label="Запрещенные"
                            className={filters.filterForbidden ? 'bg-blue-400' : 'bg-gray-300'}
                            color={filters.filterForbidden ? 'primary' : 'default'}
                            onClick={() => dispatch(setFilter({ filterForbidden: !filters.filterForbidden }))}
                            clickable
                            disabled={!!showSelectedOnly}
                        />
                    </div>
                </div>
            </div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 items-start mb-2 w-full">
                <div className="w-full">
                    <TimePicker
                        className="w-full mt-0"
                        label={`Время начала турнира (${filters.tz.label})`}
                        ampm={false}
                        value={startTime}
                        onChange={setStartTime}
                        renderInput={params => <TextField {...params} variant="outlined" fullWidth />}
                        disabled={!!showSelectedOnly}
                    />
                </div>
                <div className="w-full">
                    <Select label="День" options={weekDays} value={startDay} onChange={setStartDay} multiple={false} disabled={!!showSelectedOnly} />
                </div>
                <div className="w-full">
                    <TimePicker
                        className="w-full my-0"
                        ampm={false}
                        label={`Время окончания турнира (${filters.tz.label})`}
                        value={endTime}
                        onChange={setEndTime}
                        renderInput={params => <TextField {...params} variant="outlined" fullWidth />}
                        disabled={!!showSelectedOnly}
                    />
                </div>
                <div className="w-full">
                    <Select label="День" options={weekDays} value={endDay} onChange={setEndDay} multiple={false} disabled={!!showSelectedOnly} />
                </div>
                <div className="w-full">
                    <Select label="Часовой пояс" options={timezones} value={filters.tz} onChange={tz => changeTimezone(tz.value)} multiple={false} disabled={!!showSelectedOnly} />
                </div>
            </div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 items-start mb-2 w-full">
                <div className="w-full">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Минимальный buy-in"
                        type="number"
                        value={filters.biStart ?? undefined}
                        onChange={({ target }) => dispatch(setFilter({ biStart: target.value }))}
                        disabled={!!showSelectedOnly}
                    />
                </div>
                <div className="w-full">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Максимальный buy-in"
                        type="number"
                        value={filters.biEnd ?? undefined}
                        onChange={({ target }) => dispatch(setFilter({ biEnd: target.value }))}
                        disabled={!!showSelectedOnly}
                    />
                </div>
                <div className="w-full">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Минимально входов"
                        type="number"
                        value={filters.minEntries ?? undefined}
                        onChange={({ target }) => dispatch(setFilter({ minEntries: target.value }))}
                        disabled={!!showSelectedOnly}
                    />
                </div>
                <div className="w-full">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Максимально входов"
                        type="number"
                        value={filters.maxEntries ?? undefined}
                        onChange={({ target }) => dispatch(setFilter({ maxEntries: target.value }))}
                        disabled={!!showSelectedOnly}
                    />
                </div>
            </div>
            <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-4 items-start mb-2 w-full">
                <div className="flex flex-row w-full justify-between">
                    <div>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value={showSelectedOnly}
                                    control={<Switch color="primary" />}
                                    label="Только выбранные турниры"
                                    labelPlacement="end"
                                    onChange={toggleShowSelectedOnly}
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                    <div>
                        <Button variant="outlined" disabled={mutation.isLoading || !!showSelectedOnly} size='large' startIcon={<SearchIcon />} onClick={
                            () => {
                                resetSelection();
                                handleSearchClick();
                            }
                        }>Обновить</Button>
                    </div>
                </div>
            </div>
            {/* {filters.rooms.includes("GGNetwork") && maxBuyinQuery.data && <div className="w-full border border-gray-200 rounded-lg bg-yellow-100 p-4 mb-4 text-base"> */}
            {/*     Max GGNetwork buyin: ${maxBuyinQuery.data?.max_amount.USD}, ¥{maxBuyinQuery.data?.max_amount.CNY}. */}
            {/* </div>} */}
            {selectedCount > 0 && <div className="w-full border border-gray-200 rounded-lg bg-blue-300 p-2 mb-4 text-base">
                Выбрано {selectedCount}, ABI ${selectedAbi.toFixed(2)}, загрузка ${selectedTotaolBi.toFixed(2)}.
            </div>
            }
            <div className="w-full relative shadow-md rounded overflow-hidden" style={{ height: 800 }}>
                {mutation.isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
                        <CircularProgress />
                    </div>
                )}
                {!mutation.isLoading && (
                    <>
                        <AgGridReact
                            onGridReady={onGridReady}
                            isRowSelectable={isRowSelectable}
                            suppressRowClickSelection={true}
                            rowSelection="multiple"
                            onSelectionChanged={onSelectionChanged}
                            defaultColDef={{
                                flex: 1,
                                sortable: true,
                                floatingFilter: true,
                                suppressMenu: true,
                                floatingFilterComponentParams: { suppressFilterButton: true },
                            }}
                            rowClassRules={{
                                'bg-transparent': ({ data }) => data.network === "GGNetwork" && data.score !== -2,
                                'bg-green-100': ({ data }) => data.score === 1 && data.network !== "GGNetwork",
                                'bg-green-200': ({ data }) => data.score === 2 && data.network !== "GGNetwork",
                                'bg-yellow-100': ({ data }) => data.score === 0 && data.network !== "GGNetwork",
                                'bg-red-100': ({ data }) => data.score === -1 && data.network !== "GGNetwork",
                                'bg-gray-400': ({ data }) => data.score === -2,
                            }}
                            frameworkComponents={{
                                MaxNumberFilter: MaxNumberFilter,
                            }}
                            components={{
                                logoRenderer: RoomLogo,
                                accuracyRenderer: AccuracyLogo,
                            }}
                            enableCellTextSelection
                        >
                            <AgGridColumn
                                headerName=""
                                resizable
                                field="refunded"
                                width={40}
                                checkboxSelection
                                headerCheckboxSelection
                                flex={0}
                                cellStyle={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingLeft: '25px',
                                    paddingRight: 0,
                                }}
                            />
                            <AgGridColumn
                                headerName="Рум"
                                field="network"
                                tooltipValueGetter={({ value }) => value}
                                width={25}
                                cellRenderer="logoRenderer"
                                flex={0}
                                cellStyle={{ padding: 0 }}
                            />
                            <AgGridColumn
                                headerName="Турнир"
                                field="name"
                                width={300}
                                resizable
                                flex={null}
                                filter
                                sortable={false}
                                cellRendererFramework={nameRenderer}
                            />
                            <AgGridColumn headerName="День" field="day_number" resizable comparator={customTimeSort} valueGetter={(params) => {
                                return weekDays[params.data.day_number].label;
                            }} />
                            <AgGridColumn headerName="Старт" field="start_time" resizable sort="asc" comparator={customTimeSort} />
                            <AgGridColumn headerName="Длительность" field="duration" resizable />
                            <AgGridColumn
                                headerName="БИ"
                                field="buyin"
                                resizable
                                filter="agNumberColumnFilter"
                                floatingFilterComponent="MaxNumberFilter"
                            />
                            <AgGridColumn
                                headerName="БИ USD"
                                field="usd_buyin"
                                resizable
                                filter="agNumberColumnFilter"
                                /*floatingFilterComponent="MaxNumberFilter"*/
                                valueGetter={(params) => {
                                    return Math.round(params.data.usd_buyin);
                                }}
                            />
                            <AgGridColumn
                                headerName="Входы"
                                field="total_entries"
                                valueFormatter={params => params.value === 0 ? '-' : params.value}
                                resizable
                            />
                            <AgGridColumn
                                headerName="ROI"
                                field="roi"
                                resizable
                                sortable={true}
                                valueGetter={(params) => {
                                    return Math.round(params.data.roi);
                                }}
                                valueFormatter={(params) => params.value === 0 ? '-' : params.value}
                            />
                            <AgGridColumn
                                headerName="$/trn"
                                field="profit_per_tournament"
                                resizable
                                sortable={true}
                                valueGetter={(params) => {
                                    return params.data.profit_per_tournament.toFixed(2);
                                }}
                                valueFormatter={params => params.value === 0 ? '-' : params.value}
                            />
                        </AgGridReact>
                        {tournaments && tournaments.show_wmark && (
                            <div className="absolute inset-0 flex align-center justify-center pointer-events-none">
                                <div className="text-gray-500 mt-20 text-6xl font-bold opacity-20 w-full">
                                    <WaterMark width="90%" height="90%" />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Tournaments;
